<template>
  <div>
    <ds-header :title="$route.query.id ? '招商房源编辑' : '招商房源添加'" :showBack="true"></ds-header>
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-row type="flex" justify="start">
        <a-col :span="20">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="选择房源" prop="spaceId">
            {{ selectedRow.spacePlace }}<span v-show="selectedRow.spaceId">-</span>{{ selectedRow.spaceName }}
            <a-button @click="selectBut">{{ selectedRow.spaceId ? '重新选择房源' : '选择房源' }}</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
      <template v-if="selectedRow.spaceId">
        <div>选中房源基本信息</div>
        <div class="housingResources">
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="房源类型">
                {{ selectedRow.houseTypeVal }}
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="建筑结构">
                {{ selectedRow.structureTypeVal }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="层高" prop="storeyHeight">
                <a-input :disabled="selectedRow.spaceType != 2" v-model.trim="formData.storeyHeight"
                  placeholder="请输入层高" />
                <p v-if="selectedRow.spaceType == 2" class="housingResourcesTip">当前层高：{{ selectedRow.storeyHeightDesc }}
                </p>
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="承重" prop="bearing">
                <a-input :disabled="selectedRow.spaceType != 2" v-model.trim="formData.bearing" placeholder="请输入承重" />
                <p v-if="selectedRow.spaceType == 2" class="housingResourcesTip">当前承重：{{ selectedRow.bearingDesc }}</p>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="可租面积">
                {{ selectedRow.rentableArea }} ㎡
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </template>
      <a-row>
        <a-col :span="10">
          <a-form-model-item label="房源名称" prop="houseResourceName">
            <a-input :maxLength="50" v-model.trim="formData.houseResourceName" placeholder="请输入房源名称" />
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item label="预约电话" prop="appointmentPhone">
            <a-input v-model.trim="formData.appointmentPhone" placeholder="请输入预约电话" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start">
        <a-col :span="20">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="地址" prop="address">
            <a-input :maxLength="100" v-model.trim="formData.address" placeholder="请输入地址" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start">
        <a-col :span="20">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="可见项目" prop="visibleType">
            <a-radio-group v-model="formData.visibleType">
              <a-radio :value="0">
                全部项目展示
              </a-radio>
              <a-radio :value="1">
                当前项目展示
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start">
        <a-col :span="20">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="房源图片" prop="fileList">
            <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" accept=".png,.jpg,.jpeg"
              :file-list="formData.fileList" :beforeUpload="beforeUpload" @preview="handlePreview"
              @change="handleChange">
              <div v-if="formData.fileList.length < 9">
                <a-icon type="plus" />
                <!-- <div class="ant-upload-text">Upload</div> -->
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
            <div>请上传房源图片，最多九张</div>
          </a-form-model-item>
        </a-col>
      </a-row>



      <a-row>
        <a-col :span="10">
          <a-form-model-item label="价格" prop="price">
            <a-input-number style="width: 200px;" :min="0" :precision="2" v-model.trim="formData.price"
              placeholder="请输入价格" />
            元/㎡/天
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item label="起租期" prop="onHireDate">
            <a-input :maxLength="20" v-model.trim="formData.onHireDate" placeholder="请输入起租期" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10">
          <a-form-model-item label="房源标签" prop="labels">
            <a-input :maxLength="10" style="width: 200px;" v-model.trim="keywordValue" placeholder="请输入房源标签" /><a-button
              shape="circle" style="margin-left: 10px;" icon="plus" @click="addKeyword()" />
            <div>
              <a-tag v-for="item in formData.labels" :key="item" @close="() => deleteKeyword(item)" :closable="true">{{
                item }}</a-tag>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item label="供暖" prop="heating">
            <a-input :maxLength="20" v-model.trim="formData.heating" placeholder="请输入供暖" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10">
          <a-form-model-item label="装修情况" prop="fitmentType">
            <a-select placeholder="请选择装修情况" :getPopupContainer="(triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
              " v-model="formData.fitmentType" style="width: 200px">
              <a-select-option v-for="item in renovationList" :value="item.value" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item label="朝向" prop="orientationType">
            <a-select placeholder="请选择朝向" :getPopupContainer="(triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
              " v-model="formData.orientationType" style="width: 200px">
              <a-select-option v-for="item in towardList" :value="item.value" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start">
        <a-col :span="20">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="房源详情"
            prop="houseResourceDetails">
            <!-- <quill-editor v-model="formData.houseResourceDetails" :options="editorOption" ref="myQuillEditor1"
              @change="onUseEditorChange($event)">
            </quill-editor> -->
            <div class="content">
              <div id="editer"></div>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>


    </a-form-model>
    <div class="displayFlex">
      <a-button @click="back">
        取消
      </a-button>
      <a-button style="margin-left: 20px;" type="primary" @click="submitBut">
        确定
      </a-button>
    </div>
    <a-modal title="选择房源" width="80%" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <selectSpace ref="selectSpace" v-if="visible"></selectSpace>
    </a-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import selectSpace from '../components/selectSpace.vue'
import { IMG_API } from "@/config/index";
import { housingResourcesRule, fieldName, editorOption, houseTypeList, structureTypeList, renovationList, towardList, addRules } from "../../index"
import * as api from "@/api/lease";
import wangEditor from "wangeditor";
export default {
  components: { quillEditor, selectSpace },
  data() {
    const checkSpaceId = (rule, value, callback) => {
      if (this.formData.spaceId) {
        return callback();
      }
      // 不合法
      callback(new Error('请选择房源'));
    };
    const checkDetail = (rule, value, callback) => {
      if (this.formData.houseResourceDetails) {
        return callback();
      }
      // 不合法
      callback(new Error('请输入房源详情'));
    };
    return {
      formData: {
        fileList: [],
        labels: [],
        visibleType: 0
      },
      IMG_API,
      previewVisible: false,
      previewImage: '',
      rules: {
        ...addRules,
        spaceId: [{ required: true, validator: checkSpaceId, }],
        houseResourceDetails: [{ required: true, validator: checkDetail, }],
      },
      selectedRow: {},
      visible: false,
      houseTypeList,
      renovationList,
      structureTypeList,
      towardList,
      projectList: [],
      fieldName,
      editorOption,
      keywordValue: '',
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail()
    }
    this.createEditor()
  },
  methods: {
    // 创建富文本
    async createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.formData.houseResourceDetails  = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "table",
        "image",
      ];
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) { },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      // 查看的时候禁用富文本
      if (this.$route.query.type === "info") {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
    onUseEditorChange(event) {
      event.quill.deleteText(300, 1)
      if (this.formData.houseResourceDetails === 0) {
        this.useKnowLen = 0
      } else {
        this.useKnowLen = event.quill.getLength() - 1
      }
    },
    selectBut() {
      this.visible = true
    },
    handleOk() {
      let that = this
      let data = this.$refs.selectSpace.selectedRow
      if (data.spaceId) {
        if (data.buildingType) {
          data.houseTypeVal = this.houseTypeList[Number(data.buildingType) - 1].label
        }
        if (data.structureType) {
          data.structureTypeVal = this.structureTypeList[Number(data.structureType) - 1].label
        }
        this.selectedRow = data
        let obj = {
          ...this.formData,
          spaceId: data.spaceId,
          storeyHeight: data.spaceType == 2 ? data.storeyHeight : data.storeyHeight + '米',
          bearing: data.spaceType == 2 ? data.bearing : data.bearing + 'KN/㎡'
        }
        that.formData = obj
        this.visible = false
        that.$refs.ruleForm.validateField('spaceId')
      } else {
        this.$message.error('请选择房源数据')
      }
    },
    handleCancel() {
      this.visible = false
    },
    // 预览图片
    handlePreview(file) {
      this.previewImage = file.url ? file.url : file.response.redirect_uri;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.formData.fileList = fileList
    },
    //上传文件之前校验图片大小
    beforeUpload(file, fileList) {
      const isJPG =
        fileList[0].type === "image/jpeg" || fileList[0].type === "image/png" || fileList[0].type === "image/jpg";
      return new Promise(async (resolve, reject) => {
        if (!isJPG) {
          this.$message.error("请上传图片");
          return reject(false);
        }
        return resolve(isJPG)
      })
    },
    addKeyword() {
      console.log(this.formData.labels);
      if (!this.keywordValue) {
        return false
      }
      if (this.formData.labels.length >= 5) {
        this.$message.error('最多添加5个标签');
        return false

      }
      let type = this.formData.labels.some((item) => {
        return this.keywordValue == item
      })
      if (type) {
        return false
      }
      this.formData.labels.push(this.keywordValue)
      this.keywordValue = ''
    },
    submitBut() {
      let that = this
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            this.editData()
          } else {
            this.addData()
          }

        }
      });
    },
    async editData() {
      let list = []
      this.formData.fileList.forEach(item => {
        list.push(item.response ? item.response.redirect_uri : item.url)
      })

      let obj = {
        ...this.formData,
        urlList: list,
        labels: this.formData.labels.join(','),
        houseType: this.selectedRow.buildingType,
        structureType: this.selectedRow.structureType,
        area: this.selectedRow.rentableArea,
      }
      if (this.formData.visibleType == 1) {
        obj.projectId = this.selectedRow.projectId
      }
      delete obj.fileList
      await api.updateHousingResources(obj).then((res) => {
        if (res.code === '200') {
          this.$message.success('添加成功')
          this.$router.back()
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    deleteKeyword(removedTag) {
      const tags = this.formData.labels.filter((tag) => tag !== removedTag)
      this.formData.labels = tags
      // this.formData.keywordList.splice(index, 1);
    },
    async addData() {
      let list = []
      this.formData.fileList.forEach(item => {
        list.push(item.response.redirect_uri)
      })
      let obj = {
        ...this.formData,
        urlList: list,
        labels: this.formData.labels.join(','),
        houseType: this.selectedRow.buildingType,
        structureType: this.selectedRow.structureType,
        area: this.selectedRow.rentableArea,
      }
      if (this.formData.visibleType == 1) {
        obj.projectId = this.selectedRow.projectId
      }
      delete obj.fileList
      await api.addHousingResources(obj).then((res) => {
        if (res.code === '200') {
          this.$message.success('添加成功')
          this.$router.back()
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    back() {
      this.$router.back()
    },
    async getDetail() {
      const res = await api.getHousingResourcesDetail(this.$route.query.id);
      if (res.code === '200') {
        console.log(res.data);
        let obj = {
          ...res.data,
        }
        if (res.data.labels) {
          obj.labels = res.data.labels.split(',')
        } else {
          obj.labels = []
        }
        if (res.data.urlList) {
          let list = []
          res.data.urlList.forEach((item, index) => {
            let obj = {
              url: item,
              status: 'done',
              uid: index,
            }
            list.push(obj)
          })
          obj.fileList = list
        } else {
          obj.fileList = []
        }
        let selectedRow = {
          spacePlace: res.data.spacePlace,
          spaceId: res.data.spaceId,
          structureType: res.data.structureType,
          spaceName: res.data.spaceName,
          houseTypeVal: res.data.houseType ? this.houseTypeList[Number(res.data.houseType) - 1].label : '',
          buildingType: res.data.houseType,
          structureTypeVal: res.data.structureType ? this.structureTypeList[Number(res.data.structureType) - 1].label : '',
          spaceType: res.data.spaceType,
          rentableArea: res.data.area,
          bearingDesc: res.data.bearingDesc,
          storeyHeightDesc: res.data.storeyHeightDesc
        }
        this.selectedRow = selectedRow
        this.editor.txt.html(res.data.houseResourceDetails);
        this.formData = obj
      } else {
        that.$message.error(res.msg);
      }
    }

  }

}
</script>

<style scoped lang="scss">
.housingResources {
  border: 1px solid #d9d9d9;
  margin: 24px 0px;
  padding-top: 24px;
}

.displayFlex {
  display: flex;
  justify-content: center;
}

.housingResourcesTip {
  font-size: 12px;
  line-height: 16px;
}



::v-deep .w-e-toolbar {
  z-index: 3 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
</style>